<template>
  <div class="flex flex-col h-screen">
    <header class="w-full h-14 px-4 border-b bg-white flex items-center">
      <div class="flex items-center justify-between h-full w-full">
        <div class="flex items-center space-x-2 h-full relative flex-grow">
          <vs-button class="exitBtn" @click="$router.push({ name: 'themes' })" border>
            <svg-icon icon-class="arrow_left" className="primary xs"></svg-icon>
          </vs-button>
          <div class="exitSpan">
            <span>離開</span>
          </div>
          <h1 class="themeTitle text-limit-1">{{ generalData.title }}</h1>
        </div>
        <div class="hidden lg:flex items-center h-full space-x-2">
          <div class="displayMode" :class="{ transformY0: displayMode === 'pc' }">
            <vs-button title="切換電腦版" transparent @click="changeDisplayMode('pc')">
              <svg-icon v-if="displayMode === 'pc'" icon-class="pc" className="primary "></svg-icon>
              <svg-icon v-else icon-class="pc" className="gray-divide "></svg-icon>
            </vs-button>
          </div>
          <div class="displayMode" :class="{ transformY0: displayMode === 'ipad' }">
            <vs-button title="切換平板版" transparent @click="changeDisplayMode('ipad')">
              <svg-icon v-if="displayMode === 'ipad'" icon-class="ipad" className="primary "></svg-icon>
              <svg-icon v-else icon-class="ipad" className="gray-divide "></svg-icon>
            </vs-button>
          </div>
          <div class="displayMode" :class="{ transformY0: displayMode === 'mobile' }">
            <vs-button title="切換手機版" transparent @click="changeDisplayMode('mobile')">
              <svg-icon v-if="displayMode === 'mobile'" icon-class="mobile" className="primary "></svg-icon>
              <svg-icon v-else icon-class="mobile" className="gray-divide "></svg-icon>
            </vs-button>
          </div>
        </div>
        <!-- 行動裝置版漢堡按鈕 -->
        <button @click="open_theme_edit_menu" class="lg:hidden h-full">
          <svg-icon icon-class="hamburger" className="primary" style="width: 30px"></svg-icon>
        </button>
      </div>
    </header>

    <main class="w-full flex flex-1">
      <!-- 第一層選單 -->
      <nav class="nav" :style="{ transform: _theme_edit_menu ? 'translateX(0)' : '' }">
        <header class="flex items-center justify-between px-4 lg:hidden sticky top-0 bg-white border-b z-10" style="min-height: 56px">
          <p class="font-medium text-base text-gray-text_dark">佈景主題設定</p>
          <svg-icon @click="$store.dispatch('closeOverlay')" icon-class="cancel" className="gray-dark"></svg-icon>
        </header>

        <section v-if="is_API_loaded" class="nav_body">
          <div class="nav_title" @click="openSubMenu('一般設定')">
            <p>一般設定</p>
          </div>
          <div class="nav_title" @click="openSubMenu('頁首設定')">
            <p>頁首設定</p>
          </div>

          <!-- 拖曳 -->
          <draggable
            key="draggable"
            group="list"
            v-model="blockData"
            tag="ul"
            handle=".handle"
            class="space-y-4"
            :empty-insert-threshold="emptyInsertThreshold"
            :fallbackOnBody="fallbackOnBody"
            :swapThreshold="swapThreshold"
            :animation="animation"
            :dragClass="dragClass"
            :chosenClass="chosenClass"
            :ghostClass="ghostClass"
            @change="onChange"
          >
            <li
              class="nav_title"
              v-for="(block, index) in blockData"
              :key="block.shtno"
              @click="openSubMenu(newBlockArray[block.type].label, block.shtno, block.type, index)"
            >
              <svg-icon class="handle absolute left-0 top-1/2 transform -translate-y-1/2" icon-class="drag" className="success"></svg-icon>
              <p>{{ newBlockArray[block.type].label }}</p>
              <vs-button
                title="切換成隱藏"
                @click.stop="enableBlock(block.shtno, block.isenable, index)"
                v-if="block.isenable"
                transparent
                size="mini"
              >
                <svg-icon icon-class="eye_view" className="gray-dark"></svg-icon>
              </vs-button>
              <vs-button title="切換成顯示" @click.stop="enableBlock(block.shtno, block.isenable, index)" v-else transparent size="mini">
                <svg-icon icon-class="eye_hide" className="gray-divide"></svg-icon>
              </vs-button>
            </li>
          </draggable>
          <!-- li骨架屏 -->
          <section>
            <skeleton v-if="li_skeleton" height="48px" />
          </section>

          <div class="nav_title" @click="openSubMenu('頁尾設定')">
            <p>頁尾設定</p>
          </div>
        </section>

        <!-- 骨架屏 -->
        <section v-else class="nav_body">
          <skeleton v-for="(item, index) in 8" :key="index" height="48px" />
        </section>

        <footer class="flex items-center justify-between px-4 sticky bottom-0 bg-white border-t z-10" style="min-height: 56px">
          <vs-button v-if="is_API_loaded" @click.stop="dropdownClick(99)" transparent color="success" style="overflow: visible">
            <div class="flex items-center space-x-1">
              <svg-icon icon-class="add" className="success"></svg-icon>
              <span class="font-medium text-sm">新增區塊</span>
            </div>
            <transition name="fade">
              <dropdown v-if="dropdownIndex === 99">
                <div class="space-y-1">
                  <vs-button
                    @click="newBlock(item.type, item.title)"
                    v-for="item in newBlockArray"
                    :key="item.type"
                    transparent
                    size="large"
                    color="success"
                    >{{ item.label }}</vs-button
                  >
                </div>
              </dropdown>
            </transition>
          </vs-button>
          <!-- 按鈕骨架屏 -->
          <skeleton v-else width="80px" height="20px" />
        </footer>
      </nav>
      <!-- 第二層選單 -->
      <section class="sub_nav" :class="[subMenu ? 'transform translate-x-0' : 'transform -translate-x-full']">
        <header class="flex items-center justify-between px-4 sticky top-0 bg-white border-b z-10" style="min-height: 56px">
          <div class="flex items-center space-x-2">
            <vs-button @click="subMenu = false" transparent>
              <svg-icon icon-class="triangle" className="gray-dark xs" class="transform rotate-90"></svg-icon>
            </vs-button>
            <p class="font-medium text-base text-gray-text_dark">{{ subMenuTitle }}</p>
          </div>
        </header>

        <div class="nav_body">
          <!-- 一般設定 -->
          <section v-if="subMenuTitle === '一般設定'" class="space-y-4">
            <div>
              <p class="bigTitle">字型</p>
              <div v-if="fontFamily.length > 0">
                <vs-select v-model="generalData.wfamily">
                  <vs-option v-for="(font, index) in fontFamily" :key="index" :value="font.title" :label="font.title">
                    {{ font.title }}
                  </vs-option>
                </vs-select>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">大標題</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontTitleSize.length > 0">
                    <vs-select v-model="generalData.wsize1">
                      <vs-option v-for="(size, index) in fontTitleSize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="generalData.wweight1">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" id="font1Color" name="font1Color" v-model="generalData.wcolor1" />
                    <span>{{ generalData.wcolor1 }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">小標題</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontSubTitleSize.length > 0">
                    <vs-select v-model="generalData.wsize2">
                      <vs-option v-for="(size, index) in fontSubTitleSize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="generalData.wweight2">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" id="font2Color" name="font2Color" v-model="generalData.wcolor2" />
                    <span>{{ generalData.wcolor2 }}</span>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div>
              <p class="bigTitle">內文</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="generalData.wsize3">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="generalData.wweight3">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" id="font3Color" name="font3Color" v-model="generalData.wcolor3" />
                    <span>{{ generalData.wcolor3 }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">連結顏色</p>
              <div class="flex items-center space-x-2">
                <input class="cursor-pointer" type="color" id="aColor" name="aColor" v-model="generalData.acolor" />
                <span>{{ generalData.acolor }}</span>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">按鈕</p>
              <div class="bigTitleSpace">
                <div>
                  <p>背景顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" id="buttonBgColor" name="buttonBgColor" v-model="generalData.bbgcolor" />
                    <span>{{ generalData.bbgcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>外框顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="buttonBorderColor"
                      name="buttonBorderColor"
                      v-model="generalData.bbcolor"
                    />
                    <span>{{ generalData.bbcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>文字顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="generalData.bcolor" />
                    <span>{{ generalData.bcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="generalData.bsize">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="generalData.bweight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div>
              <p class="bigTitle">選項 / 輸入框</p>
              <div class="bigTitleSpace">
                <div>
                  <p>背景顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" id="fieldBgColor" name="fieldBgColor" v-model="generalData.bdrbgcolor" />
                    <span>{{ generalData.bdrbgcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>外框顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="fieldBorderColor"
                      name="fieldBorderColor"
                      v-model="generalData.bdrbcolor"
                    />
                    <span>{{ generalData.bdrbcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>文字顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" id="fieldTextColor" name="fieldTextColor" v-model="generalData.bdrcolor" />
                    <span>{{ generalData.bdrcolor }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">網站背景顏色</p>
              <div class="flex items-center space-x-2">
                <input class="cursor-pointer" type="color" v-model="generalData.bgcolor" />
                <span>{{ generalData.bgcolor }}</span>
              </div>
            </div>
            <hr />
            <div class="space-y-4">
              <p class="bigTitle">商品列表</p>

              <div>
                <p>電腦版每排顯示數量</p>
                <div class="flex items-center justify-between space-x-6">
                  <slider
                    v-model="generalData.product_pctype"
                    :max="6"
                    :min="1"
                    @change="generalData.product_pctype = $event"
                    width="100%"
                  ></slider>
                  <span class="text-gray-text_light text-sm font-medium">{{ generalData.product_pctype }}</span>
                </div>
              </div>
              <div>
                <p>行動裝置版每排顯示數量</p>
                <div class="flex items-center justify-between space-x-6">
                  <slider
                    v-model="generalData.product_mbtype"
                    @change="generalData.product_mbtype = $event"
                    :max="2"
                    :min="1"
                    width="100%"
                  ></slider>
                  <span class="text-gray-text_light text-sm font-medium">{{ generalData.product_mbtype }}</span>
                </div>
              </div>
              <div>
                <p>圖片寬高比例</p>
                <div class="grid grid-cols-3 place-items-start gap-x-3">
                  <div class="flex flex-col w-full items-start space-y-1">
                    <vs-radio v-model="generalData.product_wh" val="1:1">1:1</vs-radio>
                    <div class="w-20 h-20 border">
                      <div class="h-full w-full ratioBlock"></div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full items-start space-y-1">
                    <vs-radio v-model="generalData.product_wh" val="3:4">3:4</vs-radio>
                    <div class="w-20 h-20 border flex items-center justify-center">
                      <div class="aspect_ratio_3_4 ratioBlock"></div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full items-start space-y-1">
                    <vs-radio v-model="generalData.product_wh" val="4:3">4:3</vs-radio>
                    <div class="w-20 h-20 border flex items-center justify-center">
                      <div class="aspect_ratio_4_3 ratioBlock"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="space-y-4">
              <p class="bigTitle">部落格列表</p>

              <div>
                <p>電腦版每排顯示數量</p>
                <div class="flex items-center justify-between space-x-6">
                  <slider
                    v-model="generalData.blog_pctype"
                    :max="6"
                    :min="1"
                    @change="generalData.blog_pctype = $event"
                    width="100%"
                  ></slider>
                  <span class="text-gray-text_light text-sm font-medium">{{ generalData.blog_pctype }}</span>
                </div>
              </div>
              <div>
                <p>行動裝置版每排顯示數量</p>
                <div class="flex items-center justify-between space-x-6">
                  <slider
                    v-model="generalData.blog_mbtype"
                    @change="generalData.blog_mbtype = $event"
                    :max="2"
                    :min="1"
                    width="100%"
                  ></slider>
                  <span class="text-gray-text_light text-sm font-medium">{{ generalData.blog_mbtype }}</span>
                </div>
              </div>
              <div>
                <p>圖片寬高比例</p>
                <div class="grid grid-cols-3 place-items-start gap-x-3">
                  <div class="flex flex-col w-full items-start space-y-1">
                    <vs-radio v-model="generalData.blog_wh" val="1:1">1:1</vs-radio>
                    <div class="w-20 h-20 border">
                      <div class="h-full w-full ratioBlock"></div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full items-start space-y-1">
                    <vs-radio v-model="generalData.blog_wh" val="3:4">3:4</vs-radio>
                    <div class="w-20 h-20 border flex items-center justify-center">
                      <div class="aspect_ratio_3_4 ratioBlock"></div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full items-start space-y-1">
                    <vs-radio v-model="generalData.blog_wh" val="16:9">16:9</vs-radio>
                    <div class="w-20 h-20 border flex items-center justify-center">
                      <div class="aspect_ratio_16_9 ratioBlock"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">每頁顯示數量</p>

              <div v-if="perPageArray.length > 0">
                <vs-select v-model="perPage">
                  <vs-option v-for="item in perPageArray" :key="item.value" :value="item.value" :label="item.label">
                    {{ item.label }}
                  </vs-option>
                </vs-select>
              </div>
              <p class="tips">※調整 商品列表/部落格文章列表 每頁顯示幾筆資料</p>
            </div>
          </section>
          <!-- 頁首設定 -->
          <section v-if="subMenuTitle === '頁首設定'" class="space-y-4">
            <div>
              <p>背景顏色</p>
              <div class="flex items-center space-x-2">
                <input class="cursor-pointer" type="color" v-model="generalData.top_bgcolor" />
                <span>{{ generalData.top_bgcolor }}</span>
              </div>
            </div>

            <div>
              <p>文字顏色</p>
              <div class="flex items-center space-x-2">
                <input class="cursor-pointer" type="color" v-model="generalData.top_color" />
                <span>{{ generalData.top_color }}</span>
              </div>
            </div>
            <div>
              <p>字級</p>
              <div v-if="fontBodySize.length > 0">
                <vs-select v-model="generalData.top_size">
                  <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                    {{ size.size }}px
                  </vs-option>
                </vs-select>
              </div>
            </div>
            <div>
              <p>字重</p>
              <div v-if="fontWeight.length > 0">
                <vs-select v-model="generalData.top_weight">
                  <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                    {{ weight.label }}
                  </vs-option>
                </vs-select>
              </div>
            </div>

            <div>
              <p>電腦版標誌寬度</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="generalData.top_logo_width1"
                  @change="sliderChange($event, 'generalData', null, 'top_logo_width1')"
                  :max="250"
                  :min="100"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ generalData.top_logo_width1 }}px</span>
              </div>
            </div>

            <div>
              <p>行動裝置版標誌寬度</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="generalData.top_logo_width2"
                  @change="sliderChange($event, 'generalData', null, 'top_logo_width2')"
                  :max="130"
                  :min="80"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ generalData.top_logo_width2 }}px</span>
              </div>
            </div>

            <div>
              <p>標誌位置</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="generalData.top_logo_align" :val="0">置左</vs-radio>
                <vs-radio v-model="generalData.top_logo_align" :val="1">置中</vs-radio>
              </div>
            </div>
          </section>

          <!-- 圖片橫幅 -->
          <section v-if="subMenuType === 0 && blockData[openingBlockIndex]" class="space-y-4">
            <draggable
              v-model="blockData[openingBlockIndex].contarr1"
              handle=".handle"
              class=""
              :empty-insert-threshold="emptyInsertThreshold"
              :fallbackOnBody="fallbackOnBody"
              :swapThreshold="swapThreshold"
              :animation="animation"
              :dragClass="dragClass"
              :chosenClass="chosenClass"
              :ghostClass="ghostClass"
            >
              <transition-group name="fade">
                <collapse-item v-for="(item, index) in blockData[openingBlockIndex].contarr1" :key="id + index">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <svg-icon class="handle" icon-class="drag" className="success"></svg-icon>
                      <span>圖片 {{ index + 1 }}</span>
                    </div>
                  </template>
                  <div class="space-y-4">
                    <div>
                      <div class="flex items-center justify-between">
                        <p>電腦版圖片</p>
                        <button v-if="item.pcimg" @click="item.pcimg = ''" class="text-danger font-medium hover:underline">刪除</button>
                      </div>
                      <div class="upload">
                        <img v-if="item.pcimg" :src="item.pcimg" class="uploadImg" />
                        <div v-else class="flex flex-col items-center my-4">
                          <svg-icon icon-class="upload" className="gray-divide xl"></svg-icon>
                          <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                        </div>
                        <input
                          type="file"
                          @change="uploadImg($event, 'blockData', openingBlockIndex, 'contarr1', index, 'pcimg')"
                          accept="image/*"
                          class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center justify-between">
                        <p>行動裝置版圖片</p>
                        <button v-if="item.mbimg" @click="item.mbimg = ''" class="text-danger font-medium hover:underline">刪除</button>
                      </div>
                      <div class="upload">
                        <img v-if="item.mbimg" :src="item.mbimg" class="uploadImg" />
                        <div v-else class="flex flex-col items-center my-4">
                          <svg-icon icon-class="upload" className="gray-divide xl"></svg-icon>
                          <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                        </div>
                        <input
                          type="file"
                          @change="uploadImg($event, 'blockData', openingBlockIndex, 'contarr1', index, 'mbimg')"
                          accept="image/*"
                          class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                        />
                      </div>
                    </div>
                    <div>
                      <p class="">標題</p>
                      <vs-input :maxlength="maxLength.title" v-model="item.title">{{ item.title }}</vs-input>
                    </div>
                    <div>
                      <p class="">按鈕文字</p>
                      <vs-input v-model="item.btitle">{{ item.btitle }}</vs-input>
                    </div>
                    <div>
                      <p class="">按鈕連結</p>
                      <vs-input placeholder="例如：https://www.yoururl.com" v-model="item.imglink"></vs-input>
                      <vs-checkbox v-model="item.imgtype_bool">連結以新視窗開啟</vs-checkbox>
                    </div>
                    <vs-button style="margin: 30px auto 30px auto" color="danger" transparent @click="deleteContent(index)"
                      ><div class="flex items-center space-x-1">
                        <span class="font-medium text-sm">刪除圖片橫幅</span>
                      </div></vs-button
                    >
                  </div>
                </collapse-item>
              </transition-group>
            </draggable>
            <vs-button
              style="margin: 30px auto 30px auto"
              color="success"
              transparent
              @click="newBlockContent(6)"
              :disabled="blockData[openingBlockIndex].contarr1.length >= 6"
              ><div class="flex items-center space-x-1">
                <svg-icon icon-class="add" className="success"></svg-icon>
                <span class="font-medium text-sm">新增圖片橫幅</span>
              </div></vs-button
            >
            <div>
              <p>電腦版每排顯示數量</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].pctype1"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'pctype1')"
                  :max="6"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].pctype1 }}</span>
              </div>
            </div>
            <div>
              <p>行動裝置版每排顯示數量</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].mbtype1"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'mbtype1')"
                  :max="2"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].mbtype1 }}</span>
              </div>
            </div>
            <div>
              <p>輪播間隔時間</p>
              <div class="flex items-center justify-between space-x-6 mb-10">
                <slider
                  v-model="blockData[openingBlockIndex].autoplay"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'autoplay')"
                  :data="autoplay"
                  :marks="true"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">秒</span>
              </div>
            </div>
            <div>
              <p>圖片遮罩透明度</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].opacity"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'opacity')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].opacity }}%</span>
              </div>
            </div>
            <div>
              <p>文字位置</p>
              <div v-if="position.length > 0">
                <vs-select v-model="blockData[openingBlockIndex].title_align">
                  <vs-option v-for="(item, index) in position" :key="index" :value="item.value" :label="item.label">
                    {{ item.label }}
                  </vs-option>
                </vs-select>
              </div>
            </div>

            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>

            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>

            <hr />
            <div>
              <p class="bigTitle">標題</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontTitleSize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_size">
                      <vs-option v-for="(size, index) in fontTitleSize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="font1Color"
                      name="font1Color"
                      v-model="blockData[openingBlockIndex].title_color"
                    />
                    <span>{{ blockData[openingBlockIndex].title_color }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <hr />
            <div>
              <p class="bigTitle">描述</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_size">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].descs_color" />
                    <span>{{ blockData[openingBlockIndex].descs_color }}</span>
                  </div>
                </div>
              </div>
            </div> -->
            <hr />
            <div>
              <p class="bigTitle">按鈕</p>
              <div class="bigTitleSpace">
                <div>
                  <p>背景顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].button_bgcolor" />
                    <span>{{ blockData[openingBlockIndex].button_bgcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>外框顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].button_bcolor" />
                    <span>{{ blockData[openingBlockIndex].button_bcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>文字顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].button_color" />
                    <span>{{ blockData[openingBlockIndex].button_color }}</span>
                  </div>
                </div>
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].button_size">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].button_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">換頁器</p>
              <div class="bigTitleSpace">
                <div>
                  <p>狀態</p>
                  <div class="grid grid-cols-2 place-items-start">
                    <vs-radio v-model="blockData[openingBlockIndex].page_show" :val="0">隱藏</vs-radio>
                    <vs-radio v-model="blockData[openingBlockIndex].page_show" :val="1">顯示</vs-radio>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="pageColor"
                      name="pageColor"
                      v-model="blockData[openingBlockIndex].page_color"
                    />
                    <span>{{ blockData[openingBlockIndex].page_color }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- 圖片列表 -->
          <section v-if="subMenuType === 1 && blockData[openingBlockIndex]" class="space-y-4">
            <draggable
              v-model="blockData[openingBlockIndex].contarr1"
              handle=".handle"
              class=""
              :empty-insert-threshold="emptyInsertThreshold"
              :fallbackOnBody="fallbackOnBody"
              :swapThreshold="swapThreshold"
              :animation="animation"
              :dragClass="dragClass"
              :chosenClass="chosenClass"
              :ghostClass="ghostClass"
            >
              <transition-group name="fade">
                <collapse-item v-for="(item, index) in blockData[openingBlockIndex].contarr1" :key="id + index">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <svg-icon class="handle" icon-class="drag" className="success"></svg-icon>
                      <span>圖片 {{ index + 1 }}</span>
                    </div>
                  </template>
                  <div class="space-y-4">
                    <div>
                      <div class="flex items-center justify-between">
                        <p>圖片</p>
                        <button v-if="item.pcimg" @click="item.pcimg = ''" class="text-danger font-medium hover:underline">刪除</button>
                      </div>
                      <div class="upload">
                        <img v-if="item.pcimg" :src="item.pcimg" class="uploadImg" />
                        <div v-else class="flex flex-col items-center my-4">
                          <svg-icon icon-class="upload" className="gray-divide xl"></svg-icon>
                          <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                        </div>
                        <input
                          type="file"
                          @change="uploadImg($event, 'blockData', openingBlockIndex, 'contarr1', index, 'pcimg')"
                          accept="image/*"
                          class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                        />
                      </div>
                    </div>
                    <!-- <div>
                      <div class="flex items-center justify-between">
                        <p>行動裝置版圖片</p>
                        <button v-if="item.mbimg" @click="item.mbimg = ''" class="text-danger font-medium hover:underline">刪除</button>
                      </div>
                      <div class="upload">
                        <img v-if="item.mbimg" :src="item.mbimg" class="uploadImg" />
                        <div v-else class="flex flex-col items-center my-4">
                          <svg-icon icon-class="upload" className="gray-divide xl"></svg-icon>
                          <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                        </div>
                        <input
                          type="file"
                          @change="uploadImg($event, 'blockData', openingBlockIndex, 'contarr1', index, 'mbimg')"
                          accept="image/*"
                          class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                        />
                      </div>
                    </div> -->
                    <div>
                      <p class="">標題</p>
                      <vs-input :maxlength="maxLength.title" v-model="item.title">{{ item.title }}</vs-input>
                    </div>
                    <div>
                      <p class="">描述</p>
                      <vs-input :maxlength="maxLength.imgdescs" v-model="item.imgdescs">{{ item.imgdescs }}</vs-input>
                    </div>
                    <div>
                      <p class="">連結</p>
                      <vs-input placeholder="例如：https://www.yoururl.com" v-model="item.imglink"></vs-input>
                      <vs-checkbox v-model="item.imgtype_bool">連結以新視窗開啟</vs-checkbox>
                    </div>
                    <vs-button style="margin: 30px auto 30px auto" color="danger" transparent @click="deleteContent(index)"
                      ><div class="flex items-center space-x-1">
                        <span class="font-medium text-sm">刪除圖片</span>
                      </div></vs-button
                    >
                  </div>
                </collapse-item>
              </transition-group>
            </draggable>

            <vs-button
              style="margin: 30px auto 30px auto"
              color="success"
              transparent
              @click="newBlockContent(12)"
              :disabled="blockData[openingBlockIndex].contarr1.length >= 12"
              ><div class="flex items-center space-x-1">
                <svg-icon icon-class="add" className="success"></svg-icon>
                <span class="font-medium text-sm">新增圖片</span>
              </div></vs-button
            >

            <div>
              <p>區塊標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].title">{{
                blockData[openingBlockIndex].title
              }}</vs-input>
            </div>
            <div>
              <p>電腦版每排顯示數量</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].pctype1"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'pctype1')"
                  :max="6"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].pctype1 }}</span>
              </div>
            </div>
            <div>
              <p>行動裝置版每排顯示數量</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].mbtype1"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'mbtype1')"
                  :max="2"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].mbtype1 }}</span>
              </div>
            </div>

            <div>
              <p>圖片寬高比例</p>
              <div class="grid grid-cols-3 place-items-start gap-x-3">
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="1:1">1:1</vs-radio>
                  <div class="w-20 h-20 border">
                    <div class="h-full w-full ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="3:4">3:4</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_3_4 ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="4:3">4:3</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_4_3 ratioBlock"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>內容對齊</p>
              <div v-if="align.length > 0">
                <vs-select v-model="blockData[openingBlockIndex].title_align">
                  <vs-option v-for="(item, index) in align" :key="index" :value="item.value" :label="item.label">
                    {{ item.label }}
                  </vs-option>
                </vs-select>
              </div>
            </div>
            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>
            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">標題</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontSubTitleSize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_size">
                      <vs-option v-for="(size, index) in fontSubTitleSize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="font1Color"
                      name="font1Color"
                      v-model="blockData[openingBlockIndex].title_color"
                    />
                    <span>{{ blockData[openingBlockIndex].title_color }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">描述</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_size">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].descs_color" />
                    <span>{{ blockData[openingBlockIndex].descs_color }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- 圖文編排 -->
          <section v-if="subMenuType === 2 && blockData[openingBlockIndex]" class="space-y-4">
            <draggable
              v-model="blockData[openingBlockIndex].contarr1"
              handle=".handle"
              class=""
              :empty-insert-threshold="emptyInsertThreshold"
              :fallbackOnBody="fallbackOnBody"
              :swapThreshold="swapThreshold"
              :animation="animation"
              :dragClass="dragClass"
              :chosenClass="chosenClass"
              :ghostClass="ghostClass"
            >
              <transition-group name="fade">
                <collapse-item v-for="(item, index) in blockData[openingBlockIndex].contarr1" :key="id + index">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <svg-icon class="handle" icon-class="drag" className="success"></svg-icon>
                      <span>圖文 {{ index + 1 }}</span>
                    </div>
                  </template>
                  <div class="space-y-4">
                    <div>
                      <div class="flex items-center justify-between">
                        <p>圖片</p>
                        <button v-if="item.pcimg" @click="item.pcimg = ''" class="text-danger font-medium hover:underline">刪除</button>
                      </div>
                      <div class="upload">
                        <img v-if="item.pcimg" :src="item.pcimg" class="uploadImg" />
                        <div v-else class="flex flex-col items-center my-4">
                          <svg-icon icon-class="upload" className="gray-divide xl"></svg-icon>
                          <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                        </div>
                        <input
                          type="file"
                          @change="uploadImg($event, 'blockData', openingBlockIndex, 'contarr1', index, 'pcimg')"
                          accept="image/*"
                          class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                        />
                      </div>
                    </div>
                    <!-- <div>
                      <div class="flex items-center justify-between">
                        <p>行動裝置版圖片</p>
                        <button v-if="item.mbimg" @click="item.mbimg = ''" class="text-danger font-medium hover:underline">刪除</button>
                      </div>
                      <div class="upload">
                        <img v-if="item.mbimg" :src="item.mbimg" class="uploadImg" />
                        <div v-else class="flex flex-col items-center my-4">
                          <svg-icon icon-class="upload" className="gray-divide xl"></svg-icon>
                          <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                        </div>
                        <input
                          type="file"
                          @change="uploadImg($event, 'blockData', openingBlockIndex, 'contarr1', index, 'mbimg')"
                          accept="image/*"
                          class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                        />
                      </div>
                    </div> -->
                    <div>
                      <p class="">標題</p>
                      <vs-input :maxlength="maxLength.title" v-model="item.title">{{ item.title }}</vs-input>
                    </div>
                    <div>
                      <p class="">描述</p>
                      <vs-input :maxlength="maxLength.imgdescs" v-model="item.imgdescs">{{ item.imgdescs }}</vs-input>
                    </div>
                    <div>
                      <p class="">按鈕文字</p>
                      <vs-input v-model="item.btitle">{{ item.btitle }}</vs-input>
                    </div>
                    <div>
                      <p class="">連結</p>
                      <vs-input placeholder="例如：https://www.yoururl.com" v-model="item.imglink"></vs-input>
                      <vs-checkbox v-model="item.imgtype_bool">連結以新視窗開啟</vs-checkbox>
                    </div>
                    <vs-button style="margin: 30px auto 30px auto" color="danger" transparent @click="deleteContent(index)"
                      ><div class="flex items-center space-x-1">
                        <span class="font-medium text-sm">刪除圖文</span>
                      </div></vs-button
                    >
                  </div>
                </collapse-item>
              </transition-group>
            </draggable>

            <vs-button
              style="margin: 30px auto 30px auto"
              color="success"
              transparent
              @click="newBlockContent(6)"
              :disabled="blockData[openingBlockIndex].contarr1.length >= 6"
              ><div class="flex items-center space-x-1">
                <svg-icon icon-class="add" className="success"></svg-icon>
                <span class="font-medium text-sm">新增圖文</span>
              </div></vs-button
            >

            <div>
              <p>區塊標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].title">{{
                blockData[openingBlockIndex].title
              }}</vs-input>
            </div>

            <div>
              <p>類型</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].htype" :val="0">左圖右文</vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].htype" :val="1">左文右圖</vs-radio>
              </div>
            </div>

            <div>
              <p>圖文佔比</p>
              <div class="grid grid-cols-3 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].pwh2" val="50:50">50:50</vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].pwh2" val="60:40">60:40</vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].pwh2" val="40:60">40:60</vs-radio>
              </div>
            </div>
            <div>
              <p>輪播間隔時間</p>
              <div class="flex items-center justify-between space-x-6 mb-10">
                <slider
                  v-model="blockData[openingBlockIndex].autoplay"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'autoplay')"
                  :data="autoplay"
                  :marks="true"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">秒</span>
              </div>
            </div>

            <div>
              <p>內容對齊</p>
              <div v-if="align.length > 0">
                <vs-select v-model="blockData[openingBlockIndex].title_align">
                  <vs-option v-for="(item, index) in align" :key="index" :value="item.value" :label="item.label">
                    {{ item.label }}
                  </vs-option>
                </vs-select>
              </div>
            </div>

            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>
            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>
            <hr />

            <div>
              <p class="bigTitle">標題</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontSubTitleSize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_size">
                      <vs-option v-for="(size, index) in fontSubTitleSize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].title_color" />
                    <span>{{ blockData[openingBlockIndex].title_color }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">描述</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_size">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].descs_color" />
                    <span>{{ blockData[openingBlockIndex].descs_color }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">按鈕</p>
              <div class="bigTitleSpace">
                <div>
                  <p>背景顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].button_bgcolor" />
                    <span>{{ blockData[openingBlockIndex].button_bgcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>外框顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].button_bcolor" />
                    <span>{{ blockData[openingBlockIndex].button_bcolor }}</span>
                  </div>
                </div>
                <div>
                  <p>文字顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].button_color" />
                    <span>{{ blockData[openingBlockIndex].button_color }}</span>
                  </div>
                </div>
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].button_size">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].button_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">換頁器</p>
              <div class="bigTitleSpace">
                <div>
                  <p>狀態</p>
                  <div class="grid grid-cols-2 place-items-start">
                    <vs-radio v-model="blockData[openingBlockIndex].page_show" :val="0">隱藏</vs-radio>
                    <vs-radio v-model="blockData[openingBlockIndex].page_show" :val="1">顯示</vs-radio>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="pageColor"
                      name="pageColor"
                      v-model="blockData[openingBlockIndex].page_color"
                    />
                    <span>{{ blockData[openingBlockIndex].page_color }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- 商品輪播 -->
          <section v-if="subMenuType === 3 && blockData[openingBlockIndex]" class="space-y-4">
            <div>
              <p>區塊標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].title">{{
                blockData[openingBlockIndex].title
              }}</vs-input>
            </div>

            <div v-if="typeList.length > 0">
              <p>商品分類</p>
              <vs-select placeholder="請選擇商品分類" v-model="blockData[openingBlockIndex].contarr2[0].no" :key="id + 1">
                <vs-option :label="item.title" :value="item.no" v-for="item in typeList" :key="item.no">{{ item.title }}</vs-option>
              </vs-select>
            </div>
            <div v-else>
              <p>商品分類</p>
              <vs-select disabled placeholder="此分類尚無任何項目"> </vs-select>
            </div>

            <div>
              <p>電腦版每排顯示數量</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].pctype1"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'pctype1')"
                  :max="6"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].pctype1 }}</span>
              </div>
            </div>
            <div>
              <p>行動裝置版每排顯示數量</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].mbtype1"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'mbtype1')"
                  :max="2"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].mbtype1 }}</span>
              </div>
            </div>

            <div>
              <p>輪播間隔時間</p>
              <div class="flex items-center justify-between space-x-6 mb-10">
                <slider
                  v-model="blockData[openingBlockIndex].autoplay"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'autoplay')"
                  :data="autoplay"
                  :marks="true"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">秒</span>
              </div>
            </div>

            <div>
              <p>圖片寬高比例</p>
              <div class="grid grid-cols-3 place-items-start gap-x-3">
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="1:1">1:1</vs-radio>
                  <div class="w-20 h-20 border">
                    <div class="h-full w-full ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="3:4">3:4</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_3_4 ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="4:3">4:3</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_4_3 ratioBlock"></div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>
            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">換頁器</p>
              <div class="bigTitleSpace">
                <div>
                  <p>狀態</p>
                  <div class="grid grid-cols-2 place-items-start">
                    <vs-radio v-model="blockData[openingBlockIndex].page_show" :val="0">隱藏</vs-radio>
                    <vs-radio v-model="blockData[openingBlockIndex].page_show" :val="1">顯示</vs-radio>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="pageColor"
                      name="pageColor"
                      v-model="blockData[openingBlockIndex].page_color"
                    />
                    <span>{{ blockData[openingBlockIndex].page_color }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- 商品列表 -->
          <section v-if="subMenuType === 4 && blockData[openingBlockIndex]" class="space-y-4">
            <div>
              <p>區塊標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].title">{{
                blockData[openingBlockIndex].title
              }}</vs-input>
            </div>

            <div v-if="typeList.length > 0">
              <p>商品分類</p>
              <vs-select placeholder="請選擇商品分類" v-model="blockData[openingBlockIndex].contarr2[0].no" :key="id + 2">
                <vs-option :label="item.title" :value="item.no" v-for="item in typeList" :key="item.no">{{ item.title }}</vs-option>
              </vs-select>
            </div>
            <div v-else>
              <p>商品分類</p>
              <vs-select disabled placeholder="此分類尚無任何項目"> </vs-select>
            </div>

            <div>
              <p>電腦版排版</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].pctype21"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'pctype21')"
                  :max="6"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >{{ blockData[openingBlockIndex].pctype21 }} 個 / 排</span
                >
              </div>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].pctype22"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'pctype22')"
                  :max="9"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >顯示 {{ blockData[openingBlockIndex].pctype22 }} 排</span
                >
              </div>
            </div>

            <div>
              <p>行動裝置版排版</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].mbtype21"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'mbtype21')"
                  :max="2"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >{{ blockData[openingBlockIndex].mbtype21 }} 個 / 排</span
                >
              </div>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].mbtype22"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'mbtype22')"
                  :max="9"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >顯示 {{ blockData[openingBlockIndex].mbtype22 }} 排</span
                >
              </div>
            </div>

            <div>
              <p>圖片寬高比例</p>
              <div class="grid grid-cols-3 place-items-start gap-x-3">
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="1:1">1:1</vs-radio>
                  <div class="w-20 h-20 border">
                    <div class="h-full w-full ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="3:4">3:4</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_3_4 ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="4:3">4:3</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_4_3 ratioBlock"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>
            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>
          </section>

          <!-- 文章列表 -->
          <section v-if="subMenuType === 5 && blockData[openingBlockIndex]" class="space-y-4">
            <div>
              <p>區塊標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].title">{{
                blockData[openingBlockIndex].title
              }}</vs-input>
            </div>

            <div v-if="typeList.length > 0">
              <p>部落格</p>
              <vs-select placeholder="請選擇部落格" v-model="blockData[openingBlockIndex].contarr2[0].no" :key="id + 3">
                <vs-option :label="item.title" :value="item.no" v-for="item in typeList" :key="item.no">{{ item.title }}</vs-option>
              </vs-select>
            </div>
            <div v-else>
              <p>部落格</p>
              <vs-select disabled placeholder="尚無任何部落格"> </vs-select>
            </div>

            <div>
              <p>電腦版排版</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].pctype21"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'pctype21')"
                  :max="6"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >{{ blockData[openingBlockIndex].pctype21 }} 個 / 排</span
                >
              </div>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].pctype22"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'pctype22')"
                  :max="9"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >顯示 {{ blockData[openingBlockIndex].pctype22 }} 排</span
                >
              </div>
            </div>

            <div>
              <p>行動裝置版排版</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].mbtype21"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'mbtype21')"
                  :max="2"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >{{ blockData[openingBlockIndex].mbtype21 }} 個 / 排</span
                >
              </div>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].mbtype22"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'mbtype22')"
                  :max="9"
                  :min="1"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium flex-shrink-0"
                  >顯示 {{ blockData[openingBlockIndex].mbtype22 }} 排</span
                >
              </div>
            </div>

            <div>
              <p>圖片寬高比例</p>
              <div class="grid grid-cols-3 place-items-start gap-x-3">
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="1:1">1:1</vs-radio>
                  <div class="w-20 h-20 border">
                    <div class="h-full w-full ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="3:4">3:4</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_3_4 ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="16:9">16:9</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_16_9 ratioBlock"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>內容對齊</p>
              <div v-if="align.length > 0">
                <vs-select v-model="blockData[openingBlockIndex].title_align">
                  <vs-option v-for="(item, index) in align" :key="index" :value="item.value" :label="item.label">
                    {{ item.label }}
                  </vs-option>
                </vs-select>
              </div>
            </div>
            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>
            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">標題</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontSubTitleSize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_size">
                      <vs-option v-for="(size, index) in fontSubTitleSize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].title_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input
                      class="cursor-pointer"
                      type="color"
                      id="font1Color"
                      name="font1Color"
                      v-model="blockData[openingBlockIndex].title_color"
                    />
                    <span>{{ blockData[openingBlockIndex].title_color }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p class="bigTitle">描述</p>
              <div class="bigTitleSpace">
                <div>
                  <p>字級</p>
                  <div v-if="fontBodySize.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_size">
                      <vs-option v-for="(size, index) in fontBodySize" :key="index" :value="size.size" :label="size.size + 'px'">
                        {{ size.size }}px
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>字重</p>
                  <div v-if="fontWeight.length > 0">
                    <vs-select v-model="blockData[openingBlockIndex].descs_weight">
                      <vs-option v-for="(weight, index) in fontWeight" :key="index" :value="weight.weight" :label="weight.label">
                        {{ weight.label }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>
                <div>
                  <p>顏色</p>
                  <div class="flex items-center space-x-2">
                    <input class="cursor-pointer" type="color" v-model="blockData[openingBlockIndex].descs_color" />
                    <span>{{ blockData[openingBlockIndex].descs_color }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- 自訂內文 -->
          <section v-if="subMenuType === 6 && blockData[openingBlockIndex]" class="space-y-4">
            <!-- <div>
              <p>區塊標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].title">{{
                blockData[openingBlockIndex].title
              }}</vs-input>
            </div> -->

            <div>
              <p class="title">內容</p>
              <editor
                ref="editor"
                v-model="blockData[openingBlockIndex].contarr1[0].cont"
                :content="blockData[openingBlockIndex].contarr1[0].cont"
              ></editor>
            </div>
            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>
            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>
          </section>

          <!-- 影片崁入 -->
          <section v-if="subMenuType === 7 && blockData[openingBlockIndex]" class="space-y-4">
            <div>
              <p>區塊標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].title">{{
                blockData[openingBlockIndex].title
              }}</vs-input>
            </div>
            <div>
              <p>影片標題</p>
              <vs-input :maxlength="maxLength.title" v-model="blockData[openingBlockIndex].contarr1[0].title">{{
                blockData[openingBlockIndex].contarr1[0].title
              }}</vs-input>
              <p class="tips">※若無填入，預設顯示 YouTube 影片標題</p>
            </div>
            <div>
              <p>影片網址 *</p>
              <vs-input v-model="blockData[openingBlockIndex].contarr1[0].imglink" placeholder="限崁入 YouTube 影片">{{
                blockData[openingBlockIndex].contarr1[0].imglink
              }}</vs-input>
              <p class="tips mb-1">※例如：https://www.youtube.com/watch?v=sqgxcCjD04s</p>
              <LazyYoutube
                v-if="blockData[openingBlockIndex].contarr1[0].imglink"
                :src="blockData[openingBlockIndex].contarr1[0].imglink"
                maxWidth="100%"
                :showTitle="true"
                :customTitle="blockData[openingBlockIndex].contarr1[0].title"
              >
              </LazyYoutube>
            </div>
            <div>
              <p>短描述</p>
              <vs-input :maxlength="maxLength.imgdescs" v-model="blockData[openingBlockIndex].contarr1[0].imgdescs">{{
                blockData[openingBlockIndex].contarr1[0].imgdescs
              }}</vs-input>
            </div>
            <div>
              <p>影片寬高比例</p>
              <div class="grid grid-cols-3 place-items-start gap-x-3">
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="4:3">4:3</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_4_3 ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="16:9">16:9</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_16_9 ratioBlock"></div>
                  </div>
                </div>
                <div class="flex flex-col w-full items-start space-y-1">
                  <vs-radio v-model="blockData[openingBlockIndex].pwh1" val="21:9">21:9</vs-radio>
                  <div class="w-20 h-20 border flex items-center justify-center">
                    <div class="aspect_ratio_21_9 ratioBlock"></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>區塊寬度</p>
              <div class="grid grid-cols-2 place-items-start">
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="0"> 固定 </vs-radio>
                <vs-radio v-model="blockData[openingBlockIndex].widths" :val="1"> 滿版 </vs-radio>
              </div>
            </div>
            <div>
              <p>區塊上方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_top"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_top')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_top }}px</span>
              </div>
            </div>
            <div>
              <p>區塊下方間距</p>
              <div class="flex items-center justify-between space-x-6">
                <slider
                  v-model="blockData[openingBlockIndex].spacing_bottom"
                  @change="sliderChange($event, 'blockData', openingBlockIndex, 'spacing_bottom')"
                  :max="100"
                  :min="0"
                  width="100%"
                ></slider>
                <span class="text-gray-text_light text-sm font-medium">{{ blockData[openingBlockIndex].spacing_bottom }}px</span>
              </div>
            </div>
          </section>
          <!-- 頁尾設定 -->
          <section v-if="subMenuTitle === '頁尾設定'" class="space-y-4">
            <div>
              <p>背景顏色</p>
              <div class="flex items-center space-x-2">
                <input class="cursor-pointer" type="color" v-model="generalData.footer_bgcolor" />
                <span>{{ generalData.footer_bgcolor }}</span>
              </div>
            </div>

            <div>
              <p>標題顏色</p>
              <div class="flex items-center space-x-2">
                <input class="cursor-pointer" type="color" v-model="generalData.footer_color" />
                <span>{{ generalData.footer_color }}</span>
              </div>
            </div>
            <div>
              <p>連結顏色</p>
              <div class="flex items-center space-x-2">
                <input class="cursor-pointer" type="color" v-model="generalData.footer_acolor" />
                <span>{{ generalData.footer_acolor }}</span>
              </div>
            </div>
            <div>
              <p>內容對齊</p>
              <vs-select v-model="generalData.footer_align">
                <vs-option :value="0" label="置左對齊">置左對齊</vs-option>
                <vs-option :value="1" label="置中對齊">置中對齊</vs-option>
                <vs-option :value="2" label="置右對齊">置右對齊</vs-option>
              </vs-select>
            </div>
            <hr />
            <div>
              <p class="bigTitle">社群連結</p>
              <div class="bigTitleSpace">
                <div>
                  <p>Facebook</p>
                  <vs-input placeholder="https://facebook.com/" v-model="generalData.social_fb">{{ generalData.social_fb }}</vs-input>
                </div>
                <div>
                  <p>Instagram</p>
                  <vs-input placeholder="https://instagram.com/" v-model="generalData.social_ig">{{ generalData.social_ig }}</vs-input>
                </div>
                <div>
                  <p>Twitter</p>
                  <vs-input placeholder="https://twitter.com/" v-model="generalData.social_twitter">{{
                    generalData.social_twitter
                  }}</vs-input>
                </div>
                <div>
                  <p>Youtube</p>
                  <vs-input placeholder="https://youtube.com/" v-model="generalData.social_youtube">{{
                    generalData.social_youtube
                  }}</vs-input>
                </div>
                <div>
                  <p>Tumblr</p>
                  <vs-input placeholder="https://tumblr.com/" v-model="generalData.social_tumblr">{{ generalData.social_tumblr }}</vs-input>
                </div>
                <div>
                  <p>Vimeo</p>
                  <vs-input placeholder="https://vimeo.com/" v-model="generalData.social_vimeo">{{ generalData.social_vimeo }}</vs-input>
                </div>
                <div>
                  <p>Linkedin</p>
                  <vs-input placeholder="https://linkedin.com/" v-model="generalData.social_linkedin">{{
                    generalData.social_linkedin
                  }}</vs-input>
                </div>
                <!-- <div>
                  <p>TikTok</p>
                  <vs-input placeholder="https://tiktok.com/" v-model="generalData.social_tiktok">{{ generalData.social_tiktok }}</vs-input>
                </div> -->
                <div>
                  <p>Line</p>
                  <vs-input placeholder="https://line.com/" v-model="generalData.social_line">{{ generalData.social_line }}</vs-input>
                </div>
                <div>
                  <p>WeChat</p>
                  <vs-input placeholder="https://wechat.com/" v-model="generalData.social_wechat">{{ generalData.social_wechat }}</vs-input>
                </div>
                <div>
                  <p>WhatsApp</p>
                  <vs-input placeholder="https://whatsapp.com/" v-model="generalData.social_whatapp">{{
                    generalData.social_whatapp
                  }}</vs-input>
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer
          class="flex items-center justify-between px-4 sticky bottom-0 bg-white border-t z-10"
          style="min-height: 56px"
          :class="{ 'flex-row-reverse': typeof subMenuType != 'number' }"
        >
          <vs-button
            v-if="typeof subMenuType == 'number'"
            danger
            transparent
            @click=";(delete_dialog = true), (delete_arg = `shtno,${subMenuShtno}`)"
            size="large"
            class="font-medium"
            >刪除區塊</vs-button
          >
          <div>
            <vs-button ref="saveBtn" @click="save" size="large" class="font-medium">儲存</vs-button>
          </div>
        </footer>
      </section>

      <div class="iframeContainer">
        <div v-if="iframeOverlayStatus" ref="iframeOverlay" class="w-full h-full absolute top-0 left-0"></div>
        <iframe
          id="iframe"
          name="iframe"
          scrolling="auto"
          :src="$store.state.getWebData.param.wlink"
          class="rounded-xl shadow-xl border-2 transition-all ease-in-out duration-300"
          :class="{ iframe_pc: displayMode === 'pc', iframe_ipad: displayMode === 'ipad', iframe_mobile: displayMode === 'mobile' }"
        ></iframe>
      </div>
    </main>
    <vue-progress-bar></vue-progress-bar>
    <!-- 刪除 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除後無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteBlock" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import dropdown from '@/components/dropdown'
import draggable from 'vuedraggable'
import slider from '@/components/ui_components/slider'
import collapseItem from '@/components/ui_components/collapse-item'
import editor from '@/components/editor.vue'
import { LazyYoutube } from 'vue-lazytube' // youtube影片崁入
export default {
  name: 'edit-theme',
  components: {
    dropdown,
    draggable,
    slider,
    collapseItem,
    editor,
    LazyYoutube,
  },
  computed: {
    _theme_edit_menu() {
      return this.$store.state.theme_edit_menu
    },
    // 篩選拖曳列表
    // _lists: {
    //   get: function () {
    //     let lists = []
    //     this.blockData.forEach((item) => {
    //       lists.push(item.shtno)
    //     })

    //     return lists
    //   },
    //   set: function (val) {
    //     this.sortedLists = val
    //   },
    // },
  },
  watch: {
    _theme_edit_menu(val) {
      if (!val) this.subMenu = false
    },
  },
  data() {
    return {
      is_API_loaded: false,
      iframeOverlayStatus: true,
      //! 拖曳參數 start
      animation: 150,
      forceFallback: false,
      swapThreshold: 0.3,
      fallbackOnBody: false,
      emptyInsertThreshold: 10,
      dragClass: 'dragClass', // 拖動時的懸浮樣式
      chosenClass: 'chosenClass', // 被選中的區塊樣式
      ghostClass: 'ghostClass',
      delay: 0,
      //! 拖曳參數 end
      displayMode: 'pc',
      subMenu: false, // 第二選單開關
      subMenuTitle: '', // 第二選單標題
      subMenuShtno: '', // 第二選單區塊碼
      subMenuIndex: '',
      subMenuType: '', // 第二選單類型
      generalData: {}, // 一般設定
      blockData: [],
      openingBlockIndex: '',
      delete_dialog: false,
      delete_arg: '', // 刪除區塊參數
      typeList: [], // 類型列表(type 3/4/5),
      li_skeleton: false, // 小區塊骨架屏
      // 預設字體
      fontFamily: [
        {
          title: 'Noto Sans TC',
        },
        {
          title: 'Microsoft JhengHei',
        },
        {
          title: 'Helvetica Neue',
        },
        {
          title: 'Arial',
        },
        {
          title: 'Roboto',
        },
        {
          title: 'Open Sans Pro',
        },
        {
          title: 'Montserrat',
        },
        {
          title: 'Courier New',
        },
        {
          title: 'Comic Sans MS',
        },
        {
          title: 'Georgia',
        },
        {
          title: 'Impact',
        },
      ],
      // 大標題預設大小
      fontTitleSize: [
        {
          size: 18,
        },
        {
          size: 20,
        },
        {
          size: 22,
        },
        {
          size: 24,
        },
        {
          size: 26,
        },
      ],
      // 小標題預設大小
      fontSubTitleSize: [
        {
          size: 14,
        },
        {
          size: 16,
        },
        {
          size: 18,
        },
        {
          size: 20,
        },
        {
          size: 22,
        },
      ],
      // 內文預設大小
      fontBodySize: [
        {
          size: 12,
        },
        {
          size: 13,
        },
        {
          size: 14,
        },
        {
          size: 15,
        },
        {
          size: 16,
        },
      ],
      // 字重預設大小
      fontWeight: [
        {
          weight: 400,
          label: '正常',
        },
        {
          weight: 500,
          label: '較粗',
        },
        {
          weight: 700,
          label: '更粗',
        },
      ],
      // font: '', // 選中的字體
      // fontSize1: '', // 大標題大小
      // fontWeight1: '', // 大標題字重
      // fontSize2: '', // 小標題大小
      // fontWeight2: '', // 小標題字重
      // fontSize3: '', // 內文大小
      // fontWeight3: '', // 內文字重
      // 每頁顯示幾筆資料
      perPageArray: [
        {
          label: '12筆',
          value: 12,
        },
        {
          label: '24筆',
          value: 24,
        },
        {
          label: '36筆',
          value: 36,
        },
        {
          label: '48筆',
          value: 48,
        },
        {
          label: '60筆',
          value: 60,
        },
        {
          label: '全部',
          value: 99999,
        },
      ],
      perPage: '', // 選中的顯示幾筆資料
      dropdownIndex: null,
      newBlockArray: [
        {
          type: 0,
          label: '圖片橫幅',
        },
        {
          type: 1,
          label: '圖片列表',
        },
        {
          type: 2,
          label: '圖文編排',
        },
        {
          type: 3,
          label: '商品輪播',
        },
        {
          type: 4,
          label: '商品列表',
        },
        {
          type: 5,
          label: '文章列表',
        },
        {
          type: 6,
          label: '自訂內文',
        },
        {
          type: 7,
          label: '影片崁入',
        },
      ],
      autoplay: [0, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30],
      position: [
        {
          label: '左上',
          value: 0,
        },
        {
          label: '中上',
          value: 1,
        },
        {
          label: '右上',
          value: 2,
        },
        {
          label: '左',
          value: 3,
        },
        {
          label: '中',
          value: 4,
        },
        {
          label: '右',
          value: 5,
        },
        {
          label: '左下',
          value: 6,
        },
        {
          label: '中下',
          value: 7,
        },
        {
          label: '右下',
          value: 8,
        },
      ],
      align: [
        {
          label: '置左對齊',
          value: 3,
        },
        {
          label: '置中對齊',
          value: 4,
        },
        {
          label: '置右對齊',
          value: 5,
        },
      ],
      maxLength: {
        title: 50,
        btitle: 20,
        imgdescs: 100,
      },
      id: new Date().getTime(),
    }
  },
  mounted() {
    // 監聽點擊，實現點擊dropdown之外的地方，關閉dropdown
    document.addEventListener('click', this.mouseClick)
    this.loadIframe()
  },
  created() {
    this.loadTheme()
  },
  methods: {
    // 2.3.3 佈景-讀取
    loadTheme() {
      this.$axios({
        url: 'front/pages/home/getHomeUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          shno: this.$route.params.id,
        },
      }).then((res) => {
        console.log(res)
        if (res.data.Success) {
          console.log('2.3.3 佈景-讀取', res.data.Data)
          this.is_API_loaded = true
          this.li_skeleton = false
          this.generalData = res.data.Data.data2[0]
          this.blockData = res.data.Data.data3
          // this.font = this.generalData.wfamily
          // this.fontSize1 = this.generalData.wsize1
          // this.fontSize2 = this.generalData.wsize2
          // this.fontSize3 = this.generalData.wsize3
          this.perPage = this.generalData.perpage
          // this.product_wh = this.generalData.product_wh
          // this.product_pctype = this.generalData.product_pctype
          // this.product_mbtype = this.generalData.product_mbtype
          // this.blog_wh = this.generalData.blog_wh
          // this.blog_pctype = this.generalData.blog_pctype
          // this.blog_mbtype = this.generalData.blog_mbtype
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    async save() {
      if (this.subMenuTitle === '一般設定') {
        await this.saveGeneralData()
      } else if (this.subMenuTitle === '頁首設定') {
        await this.saveHeader()
      } else if (this.subMenuTitle === '頁尾設定') {
        await this.saveFooter()
      } else {
        await this.saveBlock()
      }

      this.reloadIframe()

      // @click="reloadIframe"
      // this.reloadIframe()
    },
    // 2.3.5 佈景-修改
    saveGeneralData() {
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      return this.$axios({
        url: 'front/pages/home/uHome.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          shno: this.$route.params.id,
          title: this.generalData.title,
          isenable: this.generalData.isenable,
          bgcolor: this.generalData.bgcolor, // 背景顏色
          wfamily: this.generalData.wfamily, // 字型
          // 字體大小
          // wsize1: this.fontSize1,
          // wsize2: this.fontSize2,
          // wsize3: this.fontSize3,
          wsize1: this.generalData.wsize1,
          wsize2: this.generalData.wsize2,
          wsize3: this.generalData.wsize3,
          // 字體粗細
          wweight1: this.generalData.wweight1,
          wweight2: this.generalData.wweight2,
          wweight3: this.generalData.wweight3,
          // 字體顏色
          wcolor1: this.generalData.wcolor1,
          wcolor2: this.generalData.wcolor2,
          wcolor3: this.generalData.wcolor3,
          // 按鈕
          bbgcolor: this.generalData.bbgcolor,
          bbcolor: this.generalData.bbcolor,
          bcolor: this.generalData.bcolor,
          bsize: this.generalData.bsize,
          bweight: this.generalData.bweight,
          // 連結
          acolor: this.generalData.acolor,
          // 選項 / 輸入框
          bdrbgcolor: this.generalData.bdrbgcolor,
          bdrbcolor: this.generalData.bdrbcolor,
          bdrcolor: this.generalData.bdrcolor,
          //
          perpage: this.perPage,
          product_wh: this.generalData.product_wh,
          product_pctype: this.generalData.product_pctype,
          product_mbtype: this.generalData.product_mbtype,
          blog_wh: this.generalData.blog_wh,
          blog_pctype: this.generalData.blog_pctype,
          blog_mbtype: this.generalData.blog_mbtype,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          // this.loadTheme()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.5.2 佈景-修改頁首資料
    saveHeader() {
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      return this.$axios({
        url: 'front/pages/home/uHomeTop.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          shno: this.$route.params.id,
          top_bgcolor: this.generalData.top_bgcolor,
          top_color: this.generalData.top_color,
          top_size: this.generalData.top_size,
          top_weight: this.generalData.top_weight,
          top_logo_width1: this.generalData.top_logo_width1,
          top_logo_width2: this.generalData.top_logo_width2,
          top_logo_align: this.generalData.top_logo_align,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.5.3 佈景-修改頁尾資料
    saveFooter() {
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      return this.$axios({
        url: 'front/pages/home/uHomeFooter.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          shno: this.$route.params.id,
          footer_bgcolor: this.generalData.footer_bgcolor,
          footer_color: this.generalData.footer_color,
          footer_acolor: this.generalData.footer_acolor,
          footer_align: this.generalData.footer_align,
          social_twitter: this.generalData.social_twitter,
          social_fb: this.generalData.social_fb,
          social_ig: this.generalData.social_ig,
          social_tumblr: this.generalData.social_tumblr,
          social_youtube: this.generalData.social_youtube,
          social_vimeo: this.generalData.social_vimeo,
          social_linkedin: this.generalData.social_linkedin,
          social_tiktok: this.generalData.social_tiktok,
          social_line: this.generalData.social_line,
          social_wechat: this.generalData.social_wechat,
          social_whatapp: this.generalData.social_whatapp,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.9 佈景區塊-新增
    newBlock(type, title) {
      this.li_skeleton = true
      this.$axios({
        url: 'front/pages/home/setHomeBlock.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          shno: this.$route.params.id,
          type: type,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.loadTheme()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.10 佈景區塊-修改
    saveBlock() {
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      // 當前的區塊
      let blockObj = this.blockData[this.subMenuIndex]

      // 判斷是否開啟新視窗
      blockObj.contarr1.forEach((content) => {
        content.imgtype_bool ? (content.imgtype = 1) : (content.imgtype = 0)
      })
      // 區塊內容轉JSON
      let contentArr = JSON.stringify(blockObj.contarr1)
      let data = {
        lang: 0,
        shtno: this.subMenuShtno,
        type: this.subMenuType,
        isenable: blockObj.isenable,
        no: '',
        title: blockObj.title,
        htype: blockObj.htype,
        widths: blockObj.widths,
        pwh1: blockObj.pwh1,
        pwh2: blockObj.pwh2,
        pctype1: blockObj.pctype1,
        pctype21: blockObj.pctype21,
        pctype22: blockObj.pctype22,
        mbtype1: blockObj.mbtype1,
        mbtype21: blockObj.mbtype21,
        mbtype22: blockObj.mbtype22,
        autoplay: blockObj.autoplay,
        page_color: blockObj.page_color,
        page_show: blockObj.page_show,
        title_color: blockObj.title_color,
        title_size: blockObj.title_size,
        title_weight: blockObj.title_weight,
        title_align: blockObj.title_align,
        descs_color: blockObj.descs_color,
        descs_size: blockObj.descs_size,
        descs_weight: blockObj.descs_weight,
        descs_align: blockObj.title_align, // 位置同 title的位置
        button_bgcolor: blockObj.button_bgcolor,
        button_bcolor: blockObj.button_bcolor,
        button_color: blockObj.button_color,
        button_size: blockObj.button_size,
        button_weight: blockObj.button_weight,
        button_align: blockObj.title_align, // 位置同 title的位置
        spacing_top: blockObj.spacing_top,
        spacing_bottom: blockObj.spacing_bottom,
        opacity: blockObj.opacity,
        arr: contentArr,
      }
      if (this.subMenuType === 3 || this.subMenuType === 4 || this.subMenuType === 5) {
        data.no = blockObj.contarr2[0].no
      }
      return this.$axios({
        url: 'front/pages/home/uHomeBlock.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        loading.close()
        console.log('2.3.10 佈景區塊-修改', res)
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.12 佈景區塊-修改啟用狀態
    enableBlock(shtno, status, index) {
      status ? (status = 0) : (status = 1)

      this.$axios({
        url: 'front/pages/home/uHomeBlockEnable.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          shtno: shtno,
          isenable: status,
        },
      }).then((res) => {
        if (res.data.Success) {
          status ? (this.blockData[index].isenable = 1) : (this.blockData[index].isenable = 0)
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.reloadIframe()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.13 佈景區塊-修改排序
    sortBlock() {
      this.$axios({
        url: 'front/pages/home/uHomeBlockRank.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          shtno: JSON.stringify(this.blockData),
        },
      }).then((res) => {
        console.log('2.3.13 佈景區塊-修改排序', res)
        if (res.data.Success) {
          this.reloadIframe()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 佈景區塊內容-新增
    newBlockContent(maxNum) {
      if (this.blockData[this.openingBlockIndex].contarr1.length >= maxNum) return
      this.blockData[this.subMenuIndex].contarr1.push({
        title: '',
        title_en: '',
        title_cn: '',
        btitle: '',
        btitle_en: '',
        btitle_cn: '',
        pcimg: '',
        mbimg: '',
        imgdescs: '',
        imglink: '',
        imgtype: 0,
        imgtype_bool: false,
        cont: '',
      })
    },
    // 2.3.11 佈景區塊-刪除
    deleteBlock() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let arg = this.delete_arg.split(',')
      this.$axios({
        url: '/front/pages/home/dHomeBlock.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          shtno: arg[1],
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          // this.loadTheme()
          this.subMenuType = null
          this.delete_dialog = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          // 若是刪除區塊，第二選單關閉
          const deleteIndex = this.blockData.findIndex((block) => block.shtno === arg[1])
          this.blockData.splice(deleteIndex, 1)
          this.subMenu = false
          this.reloadIframe()
          // else if (arg[0] === 'shcno') {
          //   let deleteIndex
          //   let blockIndex = this.blockData.findIndex((block) => {
          //     deleteIndex = block.contarr1.findIndex((content) => content.shcno === arg[1])
          //     return block.contarr1.some((content) => content.shcno === arg[1])
          //   })
          //   this.blockData[blockIndex].contarr1.splice(deleteIndex, 1)
          // }
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.16 佈景區塊內容-刪除
    deleteContent(index) {
      this.blockData[this.subMenuIndex].contarr1.splice(index, 1)
    },
    // 圖片上傳
    uploadImg(e, name, index, name2, index2, name3) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this[name][index][name2][index2][name3] = e.target.result
      }
      e.target.value = ''
    },
    // iframe重新整理
    reloadIframe() {
      setTimeout(() => {
        let iframe = document.getElementById('iframe')
        // 同源才可用
        // console.log(iframe.contentWindow.location.href)
        // iframe.contentWindow.location.reload()
        iframe.src = iframe.src
      }, 1000)
    },
    // 讀取iframe
    loadIframe() {
      const loading = this.$vs.loading({
        target: this.$refs.iframeOverlay,
        background: 'rgb(220,220,220)',
        scale: '1',
        type: 'scale',
        opacity: 0.6,
        color: '#fff',
      })

      let iframe = document.getElementById('iframe')

      // let iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      // console.log(iframeDoc.readyState)

      iframe.onload = () => {
        loading.close()
        this.iframeOverlayStatus = false
      }
    },
    // 更換預覽模式
    changeDisplayMode(mode) {
      this.displayMode = mode
    },
    // 行動裝置版開啟選單
    open_theme_edit_menu() {
      this.$store.commit('THEME_EDIT_MENU_TOGGLER', {
        name: 'THEME_EDIT_MENU_TOGGLER',
      })
      // this.$store.commit('OVERLAY_TOGGLER', true)
    },
    // 開啟第二選單，並更新標題
    openSubMenu(title, shtno = '', type = '', index = '') {
      console.log(type)
      // 0圖片廣告 1圖片列表 2圖文 3商品輪播 4商品列表 5文章列表 6自訂內文 7影片區塊
      if (type === 3 || type === 4) this.getList('9mu*9JWyH/Q*v4Toh3BW6JVH3739NyeMGNdYnCNsb2Y=')
      if (type === 5) this.getList('AnfaiSvTwVHVuQNjTBvCPveVbqvfl0xC0LiT08wKiwA=')
      // 抓出當前開啟的block index
      this.openingBlockIndex = this.blockData.findIndex((item) => item.shtno === shtno)
      this.subMenuTitle = title
      this.subMenuShtno = shtno
      this.subMenuType = type
      this.subMenuIndex = index
      this.subMenu = true
    },
    // 2.2.1.3 讀取類型第二層列表
    getList(listKey) {
      this.$axios({
        url: 'front/menu/getMenuTypeList2.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          no: listKey,
          lang: 0,
        },
      }).then((res) => {
        console.log('讀取類型第二層列表', res)
        if (res.data.Success) {
          this.typeList = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    mouseClick(e) {
      this.dropdownIndex = null
    },
    dropdownClick(index) {
      // 如果是開著，就關閉
      if (this.dropdownIndex) {
        this.dropdownIndex = null
      } else {
        // 設定setTimeout 比mouseClick晚觸發
        setTimeout(() => {
          this.dropdownIndex = index
        }, 0)
      }
    },
    // 當元素被移動時觸發
    onChange(evt) {
      for (let item in evt) {
        if (item === 'added' || item === 'moved') {
          // 儲存區塊排序
          this.sortBlock()
        }
      }
    },
    // onChangeContent(evt) {
    //   for (let item in evt) {
    //     if (item === 'added' || item === 'moved') {
    //       // 儲存區塊內容排序
    //       this.sortBlockContent()
    //     }
    //   }
    // },
    sliderChange(value, name, index, name2) {
      // 區塊外的slider change
      if (index === null) {
        this[name][name2] = value
      } else {
        // 區塊內的slider change
        this[name][index][name2] = value
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.mouseClick)
  },
}
</script>

<style lang="scss" scoped>
.nav {
  @apply flex flex-col text-sm overflow-hidden transform translate-x-0 border-r w-80 fixed bg-white text-gray-text_light;
  height: calc(100% - 56px);
  @media (max-width: 1024px) {
    // transform: translateX(-100%);
    @apply transition-transform duration-500 ease-in-out h-full left-0 top-0 z-500 -translate-x-full;
  }
}

.sub_nav {
  @apply flex flex-col text-sm overflow-y-auto border-r w-80 fixed bg-white
  transition-transform duration-500 ease-in-out text-gray-text_light;
  height: calc(100% - 56px);
  @media (max-width: 1024px) {
    @apply transition-transform duration-500 ease-in-out h-full z-500 top-0 left-0;
  }

  .bigTitle {
    @apply text-base font-medium text-gray-text_dark mb-2;
  }

  .tips {
    @apply text-gray-400 text-xs mt-1;
  }
}

.nav_title {
  @apply flex items-center justify-between px-6 h-12 border rounded-xl shadow-md
  cursor-pointer font-medium flex-shrink-0
  relative
  hover:bg-gray-100
  transition-colors duration-75;
}

.nav_body {
  @apply space-y-4 h-full overflow-y-auto overflow-x-hidden p-4;
}

.iframeContainer {
  @apply bg-gray-background w-full p-4 flex items-center justify-center relative;
  @screen lg {
    width: calc(100% - 320px);
    // height: calc(100% - 12px);
    left: 320px;
  }
}

.displayMode {
  @apply relative h-full flex items-center overflow-hidden;
  &::after {
    content: '';
    background: var(--primary);
    width: 100%;
    height: 5px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
}

.transformY0.displayMode::after {
  transform: translateY(0);
}

// 控制iframe大小
.iframe_pc {
  width: 100%;
  height: 100%;
}
.iframe_ipad {
  width: 768px;
  max-width: 100%;
  height: 100%;
}
.iframe_mobile {
  width: 375px;
  max-width: 100%;
  height: 667px;
  max-height: 100%;
}

// hover離開按鈕時顯示文字
.exitSpan {
  @apply border-r h-full pr-2 flex items-center transition-all  absolute transform translate-x-0 opacity-0 duration-200 ease-in-out text-sm;
}
.themeTitle {
  @apply text-gray-text_dark text-xl font-medium relative transition-transform  transform translate-x-0 duration-200 ease-in-out;
}
.exitBtn:hover ~ .exitSpan {
  @apply translate-x-10 opacity-100;
}
.exitBtn:hover ~ .themeTitle {
  @apply translate-x-14;
}

// 拖曳class
.dragClass {
  @apply shadow-lg overflow-hidden border-success bg-white opacity-100 rounded-xl;
}

.chosenClass {
  @apply rounded-xl overflow-hidden;
  background: white !important;
}

.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
  // aspect-ratio: 1 / 1;
}

.uploadImg {
  width: 100%;
}

// 寬高比例斜線背景
.ratioBlock {
  background: repeating-linear-gradient(45deg, var(--success) 0, var(--gray-lightest) 1px, transparent 1px, transparent 10px);
  @apply border border-success;
}

.aspect_ratio_3_4 {
  height: 100%;
  width: calc(100% * 0.75);
}

.aspect_ratio_4_3 {
  width: 100%;
  height: calc(100% * 0.75);
}

.aspect_ratio_16_9 {
  width: 100%;
  height: calc(100% * 0.5625);
}

.aspect_ratio_21_9 {
  width: 100%;
  height: calc(100% * 0.4285);
}

// 選單內區塊中的間隔
.bigTitleSpace {
  @apply space-y-1;
}
</style>
